// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-product-page-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-pages-404-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artwork-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/artwork.js" /* webpackChunkName: "component---src-pages-artwork-js" */),
  "component---src-pages-contact-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enquiry-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/enquiry.js" /* webpackChunkName: "component---src-pages-enquiry-js" */),
  "component---src-pages-founders-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/founders.js" /* webpackChunkName: "component---src-pages-founders-js" */),
  "component---src-pages-index-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-sample-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/sample.js" /* webpackChunkName: "component---src-pages-sample-js" */),
  "component---src-pages-services-digital-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/services/digital.js" /* webpackChunkName: "component---src-pages-services-digital-js" */),
  "component---src-pages-services-hotfoil-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/services/hotfoil.js" /* webpackChunkName: "component---src-pages-services-hotfoil-js" */),
  "component---src-pages-services-letterpress-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/services/letterpress.js" /* webpackChunkName: "component---src-pages-services-letterpress-js" */),
  "component---src-pages-services-thermal-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/services/thermal.js" /* webpackChunkName: "component---src-pages-services-thermal-js" */),
  "component---src-pages-staff-js": () => import("/Users/stoyan/Projects/Calf-Hey/calf-hey/src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/stoyan/Projects/Calf-Hey/calf-hey/.cache/data.json")

